/*
Theme Name: Monica Khirallah Law Firm Theme
Theme URI: http://creativepickle.com/
Description: Custom Theme for Monica Khirallah Law Firm
Author: CreativePickle
Version: 1.0
Tags: 
*/

@import url('reset.css');
@import (less) "mixins.less";

/****LAYOUT****/
body > #container {
     flex: 1; /* 2 */
  width: 100%;
  position:relative;
  background:url(images/tile.png) repeat 0 0;
  &:after{
      content: '\00a0'; /* &nbsp; */
    display: block;
    height: 0px;
    visibility: hidden;
      }
    }

body{
    background:#fff url(images/bg.png) repeat 0 0;
    display: flex;
  flex-direction: column;
  height: 100vh; /* 1, 3 */
    &.page-template-homepage.php{
        
    }
}

.central{
    width:100%;
    max-width:@centralWidth!important;
    margin-left: auto;
    margin-right:auto;
    padding:0 2% 0 2%!important;
    position:relative;
    }


#main {
    width:@mainWidth;
	padding:0;
	margin:0px auto;
    }
    
#content{
    width:@mainWidth;
	padding:30px 0 30px 0;
	.bodyfont();
	&.no-pad{
	    padding:0;
	    }
	}
	
#content-wrap{
   
    }

#main-content{
	padding:10px;
    line-height:1.5;
    ul{
        padding:10px 0 10px 0;
        li{
            background:url(images/bullet.png) no-repeat 0 0px;
            padding:0 0 0 20px;
            margin-bottom:8px;
            }
        }
    ul.inline{
        li{
            display:inline-block;
            width:30%;
            }
        }
    img, .image-scaler{
        border:2px solid @accent;
        .box-shadow();
        }
    }
	
#sidebar{
	padding:10px;
	}
	
ul.subpage-menu{
    width:100%;
    padding:0;
    margin:0 0 40px 0;
    >li{
        display:block;
        margin:0;
        padding:0;
        >a{
            display:block;
            padding:10px;
            border-top: 2px solid @defaultBG;
            .transition(all linear 130ms);
            font-size:20px;
            .text-shadow();
            &:hover{
                background:#f1f1f1;
                padding-left:15px;
                }
            }
            &.current_page_item>a, &.current_page_ancestor>a{
                background:#f1f1f1;
                padding-left:15px;
                }
            &:first-child>a{border-top:none;}
            >ul.children{display:none;}
            &.current_page_item>ul.children, &.current_page_ancestor>ul.children{
                padding:0;
                margin:0;
                display:block;
                >li{
                    >a{
                        .italic();
                        padding:10px;
                        border-top: 2px solid #e6e6e6;
                        .transition(all linear 130ms);
                        font-size:16px;
                        .text-shadow();
                        display:block;
                        &:hover{color:@accent;}
                        }
                         &:first-child>a{border-top:none;}
                         &.current_page_item>a{color:@accent;}
                    }
                }
        }
    }

ul.document-list{
     padding:0!important;
     margin:0px 0 10px 0!important;
    li{
        background:none!important;
        padding-left:0!important;
        margin-bottom:0!important;
        a{
            display:block;
            padding:10px 0 10px 0;
            .bodyfont();
            .uppercase();
            font-weight:300;
            border-bottom:1px solid @content;
            .transition();
            i{
                .inline-block();
                font-size:20px;
                color:@content;
                margin-right:10px;
                position:relative;
                top:2px;
                }
            &:hover{
                color:@content!important;
                background:@content;
                padding-left:10px;
                }
            }
        }
    }

#footer {
	position: relative;
	width:@mainWidth;
	
	clear:both;} 
	
.footer-content{
	text-align:center;
	padding:0;
	background:transparent!important;
	}
	
#copyright{
	background:url(images/tile.png) repeat 0 0;
	border-top:3px solid #fff;
	.muli(12px);
	.uppercase();
	.italic();
	.centered();
	color:@aqua;
	letter-spacing: .12em;
	padding: 15px 0 15px 0;
	a{
		color:@aqua;
		&:hover{
			color:#fff;
			}
		}
	}
	
.section{
    width:100%;
    position:relative;
    min-height:200px;
    padding:20px 0 20px 0;
    line-height:1.5;
    font-size:18px;
    h5.section-head{
    	.goudy(36px);
    	.uppercase();
    	color:@blueDark;
    	letter-spacing: .03em;
    	margin-bottom:35px;
    	&.white{color:#fff;}
    	}
    &.diamondhead{
    	.diamond-wrap{
    		width:100%;
    		.centered();
    		margin-bottom:40px;
    		svg{
    			.inline-block();
    			width:90%;
    			max-width:160px;
    			.diamond{
    				opacity:0;
    				}
    			}
    		&.white{
    			svg{
    					.diamond{fill:#fff!important;}
    				}
    			}
    		}
    	}
    }
    
.vert-center-wrap{
    display:table;
    height:100%;
    height: inherit;
    >.vert-center{
        display:table-cell;
        vertical-align:middle;
        
        }
    }
	
/**********PAGE STYLES**********/

.callout{
    display:block;
    .uppercase();
    position:relative;
    padding:0 60px 0 60px;
    .centered();
    .quote{
        display:block;
        width:50px;
        height:50px;
        position:absolute;
        &.open{
            top:0;
            left:0;
            background:url(images/open-quote-callout.png) no-repeat 0 0;
            }
        &.close{
            bottom:0;
            right:0;
            background:url(images/close-quote-callout.png) no-repeat 0 0;
            }    
        }
    &.left{
        width:100%;
        max-width:340px;
        float:left;
        margin:0 20px 15px 0;
        }
    &.right{
        width:100%;
        max-width:340px;
        float:right;
        margin:0 0 15px 20px;
        }
    &.center{
        width:100%;
        border-top:1px solid #d9d8d3;
        border-bottom:1px solid #d9d8d3;
        padding:15px 20px 15px 20px;
        margin:10px 0 10px 0;
        .quote{
            display:none!important;
            }
        }
     }

.image-scaler{
    .scaler();
    min-width:100px;
    height:auto;
    }

/***********HEADER and NAVIGATION*********/
@menuLink: #666;
@menuLinkHover: #333;

#main-header{
    min-height:100px;
    background:transparent;
    padding:50px 0 50px 0;
    border-top:3px solid @aqua;
    .watermark{
    	width:70px;
    	.inline-block();
    	
    	}
    .logo{
    	width:80%;
    	max-width:420px;
    	margin:25px 0 25px 0;
    	}
    .fadein{
    	
    	}
}

#nav{
}

#menu-main-navigation-1{
    list-style:none;
    padding:0;
    >li{
        .inline-block();
        margin-right:2px;
        position:relative;     
    	padding:0px 15px 0px 15px;
    	border-left:2px solid @aqua;
    	&:first-child{border-left:none;}
        >a{
            .muli(16px);
            
            letter-spacing:.06em;
            display:block;
            padding:5px;
            color:@aqua;
            .uppercase();
            
            }
        a:hover{
            color:#fff;
            }
        }
    }
 
/***********HEADER STYLES*******/

h1{
    color:#333;
    font-size:36px;
    padding:0 0 12px 0;
    }
    
h2{
    font-size:30px;
    color:#333;
    padding:0 0 12px 0;
    }
    
h3{
    font-size:24px;
    color:#333;
    padding:0 0 8px 0;
    }
    
h4{
    font-size:18px;
    padding:0 0 8px 0;
    }
    
/***********BUTTONS***********/

a.button, button.submit{
    .muli(18px);
    letter-spacing: .04em;
    display:inline-block;
    background:transparent;
    padding:5px 20px 5px 20px;
    color:#fff;
    .uppercase();
    position:relative;
    border:1px solid #fff;
    .centered();
    outline:1px solid #fff;
    outline-offset:3px;
    margin-top:15px;
    .transition();
    i{
    	margin-left:10px;
    	}
    &:hover{
        background:@aqua;
        color:#fff;
        outline-offset:5px;
        padding:5px 25px 5px 25px;
        cursor: pointer;
        }
    }
    



/*********HOMEPAGE**********/


#banner{
    border-top:4px solid #fff;
    position:relative;
	padding:200px 0 200px 0;
	.centered();
	color:#fff;
    .scaler();
    .fixed-bg();
	position:relative;
	#bannertext{
		overflow:visible;
		-webkit-filter: drop-shadow( 2px 2px 2px rgba(0,0,0,.4) );
		     filter: drop-shadow( 2px 2px 2px rgba(0,0,0,.4) );
		.wordpath{
			opacity:0;
			.scale(2.2);
			transform: translateX(20px) translateY(10px);
			}
		.letterpath{
			 
			}
		}
    }
    
.overlay{
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	background:rgba(86, 95, 117, 0.7);
	z-index:1;
	}
    

    
.banner-content{
    height:100%;
    position:relative;
    z-index:50;
    h1{
        display:none;
        }
    .banner-text-wrap{
    	.inline-block();
    	width:90%;
    	max-width:900px;
    	}
    }
    
#about{
	background:@blueDark;
	color:#fff;
	overflow:visible;
	padding:120px 0 120px 0;
	border-top:2px solid #fff;
	.transition();
	background:url(images/tile.png) repeat 0 0;
	&.active{
	
		}
	.central{
		padding-left:450px!important;
		.centered();
		img.about-img{
			display:block;
			line-height:1;
			position:absolute;
			left:0;
			bottom:-120px;
			z-index:100;
			}
		}
	}

a.read-toggle{
	display:block;
	.centered();
	.muli(20px);
	color:#fff;
	letter-spacing:.05em;
	.uppercase();
	padding:10px;
	border-top:1px solid #fff;
	.transition();
	.scale(1);
	i{
		margin-left:15px;
		.transition();
		}
	&:hover{
		cursor:pointer;
		.scale(1.02);
		i{
			.rotate(90deg);
			}
		}
	}

.read-toggle-container{
	height:0;
	overflow:hidden;
	.read-block{
		opacity:0;
		}
	}
	
#practice{
	background:#fff;
	padding:80px 0 80px 0;
	overflow: hidden!important;
	.central{padding:0 110px 0 110px!important;
		}
	.bg{
		position: absolute;
		width:55%;
		right:-5%;
		top:10%;
		}
	h6{
		.goudy(24px);
		color:@aqua;
		.uppercase();
		}
	ul{
		padding:20px 0 20px 0;
		>li{
			margin:0 0 8px 0;
			padding:0 0 0 15px;
			position: relative;
			i{color:@blueLight;position: absolute;top:6px; left:0;font-size:14px;}
			}
		}
	}

#victories{
	color:#fff;
	.scaler();
	padding:60px 0 60px 0;
	h5.section-head{color:#fff;}
	.central{
		padding:0 120px 0 120px!important;
		}
	}
	
#contact{
	padding:60px 0 60px 0;
	color:#fff!important;

	}
	
#location{
	border-top:3px solid #fff;
	padding:0!important;
	background:@blueLight;
	.right, .left{padding:0!important;}
	.right{
		background:@blueLight;
		color:#fff;
		>div{
			width:100%;
			padding:60px;
			max-width:640px!important;
			}
		}
	.left{
		.acf-map{
			height:720px;
			}
		}
		ul#contact-details{
			margin:20px 0 20px 0;
		li{
			.goudy(22px);
			color:@blueDark;
			position: relative;
			margin:0 0 12px 0;
			padding: 0 0 0 40px;
			i{
				color:#fff;
				position: absolute;
				top:0;
				left:0;
				}
			}
		}
	.footer-text{
			.goudy(34px);
			.italic();
			line-height: 1.2;
		}
	}



/**********ITEMS and WIDGETS**************/

.item{
    width:100%;
    margin-bottom:30px;
    padding-bottom:30px;
    border-bottom:1px solid @content;
}

.widget{
    margin-bottom:30px;
    h2.widget-title{
        
    }
}
 

/********ALIGNMENT********/

/* Alignment */
.alignleft {
    display: inline;
	float: left;
	margin-right: 1.625em;
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.625em;
}
.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft,
img.alignright,
img.aligncenter {
    margin-bottom: 1.625em;
}	

.clear{
    clear:both;
    }  

.clearfix{
	&:after{
		content:'';
		display:block;
		clear:both;
		width:0;
		height:0;
		}
	}

.wp-caption-text{
    font-size:12px;
    .italic();
    }
    
figure{
    -webkit-margin-before: 0;
-webkit-margin-after: 0;
-webkit-margin-start: 0;
-webkit-margin-end: 0;
    }
    
.fixed-bg{
    background-attachment:fixed!important;
    }
    
.scaler{
    position:relative;
    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
}

.centered{
    text-align:center;
    }